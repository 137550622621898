import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import './App.css';
import Home from './views/Home/Home';

class App extends Component {
  render() {
    const routes = (
      <Switch>
        <Route path="/" exact component={Home} />
        <Redirect to="/" />
      </Switch>
    );

    return <div className="App">{routes}</div>;
  }
}

export default App;
