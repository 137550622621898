import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import classes from './Toolbar.module.css';

class Toolbar extends Component {
  // TODO: use NavLinks as header elements
  render() {
    return (
      <header className={classes.Toolbar}>
        <NavLink className={classes.SiteName} to="/">
          GENSER
        </NavLink>
      </header>
    );
  }
}

export default Toolbar;
