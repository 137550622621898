import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './Footer.module.css';

const footer = props => {
  const currentYear = new Date().getFullYear();
  return (
    <div className={classes.Footer}>
      <div className={classes.FooterElements}>
        <div>&copy; {currentYear} GENSER</div>
        <NavLink to="privacy-policy">Privacy Policy</NavLink>
        <NavLink to="cookie-policy">Cookie Policy</NavLink>
      </div>
    </div>
  );
};

export default footer;
