import React, { Component } from 'react';

import classes from './Home.module.css';
import Layout from '../../hoc/Layout/Layout';

class Home extends Component {
  render() {
    return (
      <Layout>
        <div className={classes.Home}>
          <div className={classes.TemporaryMessage}>
            <p>Site under construction</p>
            <p>Site en construction</p>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Home;
